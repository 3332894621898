import environment from './environment.configs';

const brand = {
  key: 'brandZ',
  path: 'brand-z',
  name: 'Brand Z',
  accessKey: environment.trustPlaceApi.accessKey,
  website: {
    url: 'https://www.trust-place.com',
    domain: 'BrandZ.com',
  },
  features: {
    withUidCheck: false,
    withCareAndMaterialsSection: true,
    withOriginsAndProvenanceSection: true,
  },
  images: {
    logo: 'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540895/certificate-request-page/brands/brand%20z/logo.png',
    homePage: null,
    homePageCompressed: null,
    certificateExample:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540558/certificate-request-page/brands/brand%20z/certificate.png',
    certificateExampleCompressed:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540610/certificate-request-page/brands/brand%20z/compressed-certificate.png',
    favicon:
      'https://res.cloudinary.com/dnkj85yan/image/upload/v1690540652/certificate-request-page/brands/brand%20z/favicon.png',
    uidExample: null,
  },
  socialLinks: {
    twitter: 'https://x.com/NFT_ownership?s=20',
    instagram: 'https://www.instagram.com/trust.place',
    linkedin: 'https://www.linkedin.com/company/trustplace',
  },
};

export default brand;
